import React, { FC } from "react";
import { Button } from "../chat-elements/Button";

export type InitialStateComponentProps = {
  botName: string;
  onClick: () => void;
  userDetails: string | null;
  proceedWithBookingButton: () => void;
  downloadReceiptButton: () => void;
  notInterestedButton: () => void;
};

export const InitialStateComponent: FC<InitialStateComponentProps> = (
  props,
) => {
  return (
    <React.Fragment>
      <Button
        type={"primary"}
        text={`Yes, let's get Quote/Book with ${props.botName}`}
        onClick={props.onClick}
      />
      {props.userDetails ? null : (
        <Button
          type={"primary"}
          text={"Login"}
          onClick={props.proceedWithBookingButton}
        />
      )}
      <Button
        type={"secondary"}
        text={"I need to download a receipt"}
        onClick={props.downloadReceiptButton}
      />
      <Button
        type={"secondary"}
        text={"No, I am not interested"}
        onClick={props.notInterestedButton}
      />
    </React.Fragment>
  );
};
