import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "./date-time-input.css";

import { ChangeEvent, useState } from "react";
import { Button } from "./Button";

export type DateTimeSelectorType = {
  date: Date;
  setDate: (date: Date) => void;
  preventBeforeDate?: Date;
  preventAfterDate?: Date;
  dateOnly?: boolean;
};

export const DateTimeSelector: React.FC<DateTimeSelectorType> = ({
  date,
  setDate,
  preventBeforeDate,
  preventAfterDate,
  dateOnly = false,
}) => {
  const [selectedValue, setSelectedValue] = useState(new Date(date));
  const handleChange = (value: DateObject) => {
    setSelectedValue(value.toDate());
  };
  console.log("Prevent before date:", preventBeforeDate);
  console.log("Prevent after date:", preventAfterDate);
  return (
    <>
      <Calendar
        value={selectedValue}
        onChange={handleChange}
        minDate={preventBeforeDate}
        maxDate={preventAfterDate}
        showOtherDays
        buttons
        // render={(value, onFocus, onChange, separator) => (
        //   <DateTimeInputField
        //     value={value}
        //     openCalendar={onFocus}
        //     onChange={onChange}
        //     onSubmit={setDate}
        //     selectedValue={selectedValue}
        //   />
        // )}
        format={"MM/DD/YYYY hh:mm A"}
        plugins={
          dateOnly
            ? []
            : [<TimePicker position="bottom" mStep={5} hideSeconds />]
        }
      />
      <Button
        type={"primary"}
        text={"Confirm"}
        onClick={() => setDate(selectedValue)}
      />
    </>
  );
};

// const DateTimeInputField: React.FC<{
//   value: string;
//   openCalendar: () => void;
//   onChange: (e: ChangeEvent<Element>) => void;
//   onSubmit: (date: Date) => void;
//   selectedValue: Date;
// }> = ({ value, openCalendar, selectedValue, onSubmit }) => {
//   return (
//     <div className="date-time-input-block">
//       <input
//         type="text"
//         className="date-time-input"
//         value={value}
//         onFocus={openCalendar}
//         readOnly
//       />
//       <Button
//         type={"primary"}
//         text={"Confirm"}
//         onClick={() => onSubmit(selectedValue)}
//       />
//     </div>
//   );
// };
