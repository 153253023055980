import React from "react";
import "./button.css";

export type ButtonType = {
  type: "primary" | "secondary" | "disabled";
  text: string;
  onClick: () => void;
};

export const Button: React.FC<ButtonType> = ({ type, text, onClick }) => {
  return (
    <button onClick={onClick} className={`button button-${type}`}>
      {text}
    </button>
  );
};
