import React, { useEffect, useRef, useState } from "react";

import "./chat.css";
import { Message } from "./Message";
import { Button } from "./chat-elements/Button";
import { AddressInput } from "./chat-elements/AddressInput";
import {
  BookingFlowState,
  getCurrentState,
  getEditState,
  nextState,
} from "../services/ChatStateMachine";
import { DateTimeSelector } from "./chat-elements/DateTimeSelector";
import {
  createBooking,
  FlightInfoResponseType,
  getAvailableCarClasses,
  getLeadTime,
  getPricing,
  getTzFromLatLng,
  isLocationAirport,
  PricingReceiptType,
  PricingResponseType,
} from "../services/ApiService";
import { EncodedLocation } from "../services/GoogleAutocompleteService";
import { formatDate } from "../services/utils";
import { getBookingDetailsMessages } from "./chat-logic/getBookingDetailsMessages";
import { InitialStateComponent } from "./chat-logic/InitialStateComponent";
import { AirlineInput, AirlineType } from "./chat-elements/AirlineInput";
import { FlightNumberInput } from "./chat-elements/FlightNumberInput";
import { DriverInstructions } from "./chat-elements/DriverInstructionsInput";
import moment from "moment-timezone";

export type SenderType = "BOT" | "USER";

export type MessageType = {
  id: number;
  content: string;
  sender: SenderType;
  editable: boolean;
  editStatus?: BookingFlowState;
};

export type FlightInfoType = {
  airline: string;
  arrival_from: string;
  flight_departure_date: string;
  landing_time: string;
  number: string;
  track_flight: boolean;
};

export type BookingState = {
  pickupAddress?: EncodedLocation;
  dropoffAddress?: EncodedLocation;
  date?: Date;
  carClass?: string;
  merchandiseCarClass?: string;
  receipt?: PricingReceiptType;
  flightArrivalDate?: Date;
  flightDepartureDate?: Date;
  flightNumber?: string;
  airlineCode?: string;
  airline?: FlightInfoType;
  flightInfo?: FlightInfoResponseType;
};

const BOT_NAMES = ["Ava", "Ella", "Mia", "Lucy", "Maya"];

export type RideOptions = {
  additionalSeat: string;
  childSeat: string;
  driverNote: string | undefined;
  secondAdditionalSeat: string;
  thirdAdditionalSeat: string;
};

const defaultRideOptions = {
  additionalSeat: "",
  childSeat: "",
  driverNote: undefined,
  secondAdditionalSeat: "",
  thirdAdditionalSeat: "",
};

export const Chat: React.FC = () => {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [bookingState, setBookingState] = useState<BookingState>({});
  const [rideOptions, setRideOptions] =
    useState<RideOptions>(defaultRideOptions);
  const div = useRef<HTMLDivElement | null>(null);
  const [pointA, setPointA] = useState<EncodedLocation | undefined>(undefined);
  const [leadTime, setLeadTime] = useState<moment.Moment | undefined>(
    undefined,
  );
  const [pricing, setPricing] = useState<PricingResponseType[]>([]);
  const [botOpen, setBotOpen] = useState(false);
  const [botName, setBotName] = useState("");
  const [timeZone, setTimeZone] = useState("America/New_York");

  useEffect(() => {
    const openBot = () => setBotOpen(true);
    setTimeout(openBot, 1500);
  }, []);

  const selectFirstPoint = async (address: EncodedLocation) => {
    setPointA(address);
    const { timezone } = await getTzFromLatLng(
      address.latitude,
      address.longitude,
    );
    setTimeZone(timezone);
    getLeadTime(address).then((leadTime) => {
      const earliestPickupTime =
        leadTime?.earliest_pickup_time || new Date().toISOString();
      const localizedTime = moment.tz(
        earliestPickupTime.replace("T", " ").split(".")[0],
        "YYYY-MM-DD HH:mm:ss",
        timezone,
      );
      if (leadTime) setLeadTime(localizedTime);
    });
  };

  const checkAirport = (address: EncodedLocation, isPickupAddress: boolean) => {
    console.log("Check Address Type and append info to encoded location");
    isLocationAirport(address)
      .then((addressWithAirportInfo) => {
        if (isPickupAddress) {
          submitMessage([addressWithAirportInfo.address || ""], {
            pickupAddress: addressWithAirportInfo,
          });
        } else {
          let rideType: BookingFlowState | undefined = undefined;

          if (bookingState.pickupAddress?.address_type === "AIRPORT") {
            rideType = BookingFlowState.INPUT_POINT_A_AIRPORT;
          }
          if (addressWithAirportInfo.address_type === "AIRPORT") {
            rideType = BookingFlowState.INPUT_POINT_B_AIRPORT;
          }

          submitMessage(
            [addressWithAirportInfo.address || ""],
            {
              dropoffAddress: addressWithAirportInfo,
            },
            rideType,
          );
        }
      })
      .catch(console.error);
  };

  useEffect(() => {
    const botNameStringIndex =
      sessionStorage.getItem("vagent") ||
      Math.floor(Math.random() * BOT_NAMES.length).toString();
    sessionStorage.setItem("vagent", botNameStringIndex);

    let currentBotName = BOT_NAMES[parseInt(botNameStringIndex)];
    setBotName(currentBotName);

    const messages: MessageType[] = [];
    messages.push({
      id: Math.random(),
      content: "Hi!",
      sender: "BOT",
      editable: false,
    });
    messages.push({
      id: Math.random(),
      content: `My name is ${currentBotName}.`,
      sender: "BOT",
      editable: false,
    });
    messages.push({
      id: Math.random(),
      content: "I can help you to get quote and book a ride with me.",
      sender: "BOT",
      editable: false,
    });
    messages.push({
      id: Math.random(),
      content: "Would you like to proceed?",
      sender: "BOT",
      editable: false,
    });
    setMessages(messages);
  }, []);

  useEffect(() => {
    if (bookingState.carClass && rideOptions.driverNote !== undefined) {
      printBookingDetails();
    }
  }, [bookingState.carClass, pricing, rideOptions]);

  useEffect(() => {
    setTimeout(
      () => div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
      150,
    );
  }, [messages]);

  useEffect(() => {
    setTimeout(
      () => div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
      250,
    );
  }, [pricing]);

  const sendMessageWithoutStateUpdate = (
    msg: string[],
    sender: SenderType = "USER",
  ) => {
    const userMessages = msg.map((message) => ({
      id: Math.random(),
      content: message,
      sender,
    })) as MessageType[];

    setMessages([...messages, ...userMessages]);
  };

  const submitMessage = (
    msg: string[],
    bookingInfo: Partial<BookingState>,
    stateOverride?: BookingFlowState,
  ) => {
    const previousState = getEditState(stateOverride || getCurrentState());
    const responseMessages = nextState(stateOverride).map((message) => {
      return {
        id: Math.random(),
        content: message,
        sender: "BOT",
        editable: false,
      };
    }) as MessageType[];

    const userMessages = msg.map((message, index) => ({
      id: Math.random(),
      content: message,
      sender: "USER",
      editable: index === 0,
      editStatus: previousState,
    })) as MessageType[];

    setMessages([...messages, ...userMessages, ...responseMessages]);

    setBookingState({ ...bookingState, ...bookingInfo });

    console.log(`bookingState: ${JSON.stringify(bookingState, null, 2)}`);
  };

  const printBookingDetails = () => {
    const bookingDetailsMessages = getBookingDetailsMessages(
      bookingState,
      rideOptions,
      timeZone,
    );
    setMessages([...messages, ...bookingDetailsMessages]);
  };

  const renderOptionsDependingOnState = () => {
    const state = getCurrentState();
    const userDetails = sessionStorage.getItem("auth_user_details");
    const isArrival = !bookingState.flightDepartureDate;
    switch (state) {
      case BookingFlowState.INITIAL:
        return (
          <InitialStateComponent
            botName={botName}
            onClick={() =>
              submitMessage([`Yes, let's get Quote/Book with ${botName}`], {})
            }
            userDetails={userDetails}
            proceedWithBookingButton={() => {
              submitMessage([`Login`], {});
              window.location.href = "/user/signin";
            }}
            downloadReceiptButton={() => {
              submitMessage(
                [`I need to download a receipt`],
                {},
                BookingFlowState.GET_A_RECEIPT,
              );
            }}
            notInterestedButton={() => {
              submitMessage(
                [`No, I am not interested`],
                {},
                BookingFlowState.CLOSE,
              );
              setBotOpen(false);
            }}
          />
        );
      case BookingFlowState.ASK_FOR_LOGIN:
        return (
          <React.Fragment>
            <Button
              type={"primary"}
              text={"Yes, proceed to login"}
              onClick={() => {
                submitMessage([`Yes, proceed to login`], {});
                window.location.href = "/user/signin";
              }}
            />
            <Button
              type={"secondary"}
              text={"No, proceed with Booking"}
              onClick={() => submitMessage([`No, proceed with Booking`], {})}
            />
          </React.Fragment>
        );
      case BookingFlowState.INPUT_POINT_A:
        return (
          <AddressInput
            previousAddress={undefined}
            setAddress={(address: EncodedLocation) => {
              selectFirstPoint(address);
              checkAirport(address, true);
            }}
            currentValue={pointA?.address}
          />
        );
      case BookingFlowState.INPUT_POINT_B:
        return (
          <AddressInput
            previousAddress={pointA}
            currentValue={bookingState.dropoffAddress?.address}
            setAddress={(address: EncodedLocation) => {
              checkAirport(address, false);
            }}
          />
        );
      case BookingFlowState.FLIGHT_ARRIVAL_DATE:
        return (
          <DateTimeSelector
            date={bookingState.flightArrivalDate || new Date()}
            setDate={(date) => {
              const localizedDate = moment(date).tz(timeZone);
              submitMessage(
                [`Flight date: ${localizedDate.toDate().toLocaleDateString()}`],
                {
                  flightArrivalDate: localizedDate.toDate(),
                },
              );
            }}
            preventBeforeDate={new Date()}
            dateOnly
          />
        );
      case BookingFlowState.FLIGHT_DEPARTURE_DATE:
        return (
          <>
            <DateTimeSelector
              date={bookingState.flightDepartureDate || new Date()}
              setDate={(date) => {
                const localizedDate = moment(date).tz(timeZone);
                submitMessage(
                  [
                    `Flight date: ${localizedDate.toDate().toLocaleDateString()}`,
                  ],
                  {
                    flightDepartureDate: localizedDate.toDate(),
                  },
                );
              }}
              preventBeforeDate={new Date()}
              dateOnly
            />

            <Button
              key={"skip-flight-info"}
              onClick={() =>
                submitMessage(
                  ["I don't want to add flight information"],
                  {},
                  BookingFlowState.INPUT_POINT_B,
                )
              }
              text="I don't want to add flight info"
              type="primary"
            />
          </>
        );
      case BookingFlowState.FLIGHT_NUMBER_INPUT:
        const flightDate = isArrival
          ? bookingState.flightArrivalDate?.toISOString().split("T")[0]
          : bookingState.flightDepartureDate?.toISOString().split("T")[0];
        return (
          <FlightNumberInput
            flight_airline={bookingState.airline?.airline || ""}
            flight_airport={
              (isArrival
                ? bookingState.pickupAddress?.iata
                : bookingState.dropoffAddress?.iata) || ""
            }
            flight_date={flightDate || ""}
            flight_type_is_arrival={isArrival}
            sendErrorMessages={(messages) => {
              sendMessageWithoutStateUpdate(messages, "BOT");
            }}
            setFlightInfo={(flightInfo) => {
              console.log(`flightInfo: ${JSON.stringify(flightInfo, null, 2)}`);
              const flightDate = isArrival
                ? moment.tz(
                    flightInfo.arrival_date_time_scheduled_local,
                    "YYYY-MM-DDTHH:mm:ss",
                    timeZone,
                  )
                : moment.tz(
                    flightInfo.departure_date_time_scheduled_local,
                    "YYYY-MM-DDTHH:mm:ss",
                    timeZone,
                  );

              submitMessage([`Flight number: ${flightInfo.flight_number}`], {
                airline: {
                  airline: flightInfo.airline.code,
                  number: flightInfo.flight_number,
                  arrival_from: flightInfo.origin_arrival_airport.iata_code,
                  flight_departure_date:
                    flightInfo.departure_date_time_scheduled_local,
                  landing_time: flightInfo.arrival_date_time_scheduled_local,
                  track_flight: true,
                },
                flightInfo,
                flightArrivalDate: isArrival ? flightDate.toDate() : undefined,
                flightDepartureDate: !isArrival
                  ? flightDate.toDate()
                  : undefined,
              });
            }}
            selectedValue={bookingState.flightNumber || ""}
          />
        );
      case BookingFlowState.AIRLINE_CODE_INPUT:
        return (
          <>
            <AirlineInput
              code={""}
              name={""}
              submitAirline={(airline: AirlineType) => {
                submitMessage([`Airline: ${airline.name}`], {
                  airline: {
                    airline: airline.code,
                    number: "",
                    arrival_from: "",
                    flight_departure_date: "",
                    landing_time: "",
                    track_flight: true,
                  },
                });
              }}
            />
          </>
        );
      case BookingFlowState.AIRPORT_PICK_DATE_TIME:
        console.log({ bookingState });
        const isDomesticFlight =
          bookingState.flightInfo?.departure_airport.country ===
            bookingState.flightInfo?.origin_arrival_airport.country &&
          (bookingState.flightInfo?.departure_airport.country === "US" ||
            bookingState.flightInfo?.departure_airport.country === "CA");
        const timeBeforeFlight = 1; // isDomesticFlight ? 1 : 2;
        const flightDepartureDate = bookingState.flightDepartureDate
          ? new Date(
              bookingState.flightInfo?.departure_date_time_scheduled_local ||
                "",
            )
          : undefined;

        if (flightDepartureDate)
          flightDepartureDate.setHours(
            flightDepartureDate.getHours() - timeBeforeFlight,
          );

        const flightArrivalDate = bookingState.flightArrivalDate
          ? new Date(
              bookingState.flightInfo?.arrival_date_time_scheduled_local || "",
            )
          : undefined;

        return (
          <DateTimeSelector
            preventAfterDate={flightDepartureDate}
            preventBeforeDate={flightArrivalDate}
            date={
              (isArrival ? flightArrivalDate : flightDepartureDate) ||
              new Date()
            }
            setDate={(date) => {
              const dateInZone = moment.tz(
                moment(date).format("YYYY-MM-DD HH:mm"),
                timeZone,
              );

              getAvailableCarClasses().then((carClasses) => {
                getPricing({
                  car_classes: carClasses,
                  date: formatDate(dateInZone.toDate()),
                  time_zone: timeZone,
                  payment_type: "CREDIT_CARD",
                  destination_location: bookingState.dropoffAddress!,
                  origin_location: bookingState.pickupAddress!,
                  luggage_count: 0,
                  number_of_passengers: 1,
                  round_trip: false,
                  use_credits: false,
                  account_id: null,
                  stops: [],
                  account_code: null,
                  customer_id: null,
                  ppc_ref_id: null,
                  promo_code: null,
                  session_id: "testing",
                })
                  .then((pricing) => {
                    setPricing(pricing);
                  })
                  .catch(() => {
                    sendMessageWithoutStateUpdate([
                      `There was an issue getting the pricing.`,
                      `Please try to use our mini booker on the website.`,
                      `Or contact us at 800-672-7676`,
                    ]);
                  });
              });
              submitMessage(
                [
                  "Selected date and time:",
                  `Date: ${dateInZone.format("YYYY-MM-DD")}`,
                  `Time: ${dateInZone.format("hh:mm a")}`,
                ],
                {
                  date: dateInZone.toDate(),
                },
              );
            }}
          />
        );
      case BookingFlowState.PICK_DATE_TIME:
        return (
          <DateTimeSelector
            preventBeforeDate={new Date(leadTime!.format("YYYY-MM-DD HH:mm"))}
            date={new Date(leadTime!.format("YYYY-MM-DD HH:mm"))}
            setDate={(date) => {
              const localizedDate = moment.tz(
                moment(date).format("YYYY-MM-DD HH:mm"),
                "YYYY-MM-DD HH:mm",
                timeZone,
              );

              getAvailableCarClasses().then((carClasses) => {
                getPricing({
                  car_classes: carClasses,
                  date: formatDate(localizedDate.toDate()),
                  time_zone: timeZone,
                  payment_type: "CREDIT_CARD",
                  destination_location: bookingState.dropoffAddress!,
                  origin_location: bookingState.pickupAddress!,
                  luggage_count: 0,
                  number_of_passengers: 1,
                  round_trip: false,
                  use_credits: false,
                  account_id: null,
                  stops: [],
                  account_code: null,
                  customer_id: null,
                  ppc_ref_id: null,
                  promo_code: null,
                  session_id: "testing",
                })
                  .then((pricing) => {
                    setPricing(pricing);
                  })
                  .catch(() => {
                    sendMessageWithoutStateUpdate([
                      `There was an issue getting the pricing.`,
                      `Please try to use our mini booker on the website.`,
                      `Or contact us at 800-672-7676`,
                    ]);
                  });
              });
              submitMessage(
                [
                  "Selected date and time:",
                  `Date: ${localizedDate.format("YYYY-MM-DD")}`,
                  `Time: ${localizedDate.format("hh:mm a")}`,
                ],
                {
                  date: localizedDate.toDate(),
                },
              );
            }}
          />
        );
      case BookingFlowState.PICK_CAR_CLASS:
        // if (pricing.length > 0)
        //   setTimeout(
        //     () =>
        //       div.current?.scrollIntoView({ behavior: "smooth", block: "end" }),
        //     150,
        //   );
        return (
          <React.Fragment>
            {pricing.map((pricing) =>
              pricing.receipt ? (
                <Button
                  key={pricing.car_class}
                  type={"primary"}
                  text={`${pricing.merchandise_car_class_desc} - ${pricing?.receipt?.total}`}
                  onClick={() => {
                    submitMessage(
                      [
                        `${pricing.merchandise_car_class_desc} - ${pricing?.receipt?.total}`,
                      ],
                      {
                        carClass: pricing.car_class,
                        merchandiseCarClass: pricing.merchandise_car_class_desc,
                        receipt: pricing.receipt,
                      },
                    );
                  }}
                />
              ) : null,
            )}
          </React.Fragment>
        );
      case BookingFlowState.CONFIRM_BOOKING:
        return (
          <>
            <Button
              type={"primary"}
              text={"No, I want to change something"}
              onClick={() => {
                submitMessage(["No, I want to change something"], {});
              }}
            />
            <Button
              type={"secondary"}
              text={"Proceed to the payment page"}
              onClick={() => {
                submitMessage(
                  ["OK, take me to the payment page"],
                  {},
                  BookingFlowState.EDIT_BOOKING,
                );
                window.location.href = createBooking(
                  bookingState,
                  rideOptions,
                  timeZone,
                );
              }}
            />
          </>
        );

      case BookingFlowState.EDIT_BOOKING:
        const isFlight = bookingState.flightInfo !== undefined;
        return (
          <>
            <Button
              type="primary"
              text="Edit pickup address"
              onClick={() =>
                submitMessage(
                  ["Edit pickup address"],
                  {
                    flightInfo: undefined,
                    flightArrivalDate: undefined,
                    flightDepartureDate: undefined,
                    flightNumber: undefined,
                    airlineCode: undefined,
                  },
                  getEditState(BookingFlowState.INPUT_POINT_A),
                )
              }
            />
            <Button
              type="primary"
              text="Edit drop-off address"
              onClick={() =>
                submitMessage(
                  ["Edit drop-off address"],
                  {
                    flightInfo: undefined,
                    flightArrivalDate: undefined,
                    flightDepartureDate: undefined,
                    flightNumber: undefined,
                    airlineCode: undefined,
                  },
                  getEditState(BookingFlowState.INPUT_POINT_B),
                )
              }
            />
            {!isFlight && (
              <Button
                type="primary"
                text="Edit travel date or time"
                onClick={() =>
                  submitMessage(
                    ["Edit travel date or time"],
                    {
                      flightInfo: undefined,
                    },
                    getEditState(BookingFlowState.PICK_DATE_TIME),
                  )
                }
              />
            )}
            {isFlight && (
              <Button
                type="primary"
                text="Edit travel date or time"
                onClick={() =>
                  submitMessage(
                    ["Edit travel date or time"],
                    {
                      flightInfo: undefined,
                    },
                    getEditState(BookingFlowState.FLIGHT_ARRIVAL_DATE),
                  )
                }
              />
            )}
            {isFlight && (
              <Button
                type="primary"
                text="Edit pickup time"
                onClick={() =>
                  submitMessage(
                    ["Edit pickup time"],
                    {},
                    getEditState(BookingFlowState.AIRPORT_PICK_DATE_TIME),
                  )
                }
              />
            )}
            {isFlight && (
              <>
                <Button
                  type="primary"
                  text="Edit airline"
                  onClick={() =>
                    submitMessage(
                      ["Edit airline"],
                      {},
                      getEditState(BookingFlowState.AIRLINE_CODE_INPUT),
                    )
                  }
                />
                <Button
                  type="primary"
                  text="Edit flight number"
                  onClick={() =>
                    submitMessage(
                      ["Edit flight number"],
                      {},
                      getEditState(BookingFlowState.FLIGHT_NUMBER_INPUT),
                    )
                  }
                />
              </>
            )}

            <Button
              type="primary"
              text="Edit driver instructions"
              onClick={() =>
                submitMessage(
                  ["Edit driver instructions"],
                  {},
                  getEditState(BookingFlowState.DRIVER_INSTRUCTIONS),
                )
              }
            />
          </>
        );

      case BookingFlowState.DRIVER_INSTRUCTIONS:
        return (
          <DriverInstructions
            driverInstructions={rideOptions.driverNote || ""}
            setDriverInstructions={(instructions) => {
              submitMessage([instructions || "No special instructions"], {});
              setRideOptions({ ...rideOptions, driverNote: instructions });
            }}
          />
        );
      case BookingFlowState.GET_A_RECEIPT:
        return (
          <>
            <Button
              type={"primary"}
              text={"Go to 'Get a Receipt' page"}
              onClick={() => {
                submitMessage(["Go to 'Get a Receipt' page"], {});
                window.location.href = "/get-a-receipt/";
              }}
            />
            {userDetails ? (
              <Button
                type={"secondary"}
                text={"Go to 'Account Center'"}
                onClick={() => {
                  submitMessage(["Go to 'Account Center'"], {});
                  window.location.href = "/account";
                }}
              />
            ) : null}
          </>
        );
    }
  };

  return (
    <React.Fragment>
      <div className={`chat-window chat-window-${botOpen ? "open" : "closed"}`}>
        <div className="chat-window-header">
          {`${botName} - Booking Virtual Agent`}
          <span
            className="chat-window-close-button"
            onClick={() => setBotOpen(false)}
          >
            X
          </span>
        </div>
        <div className="messages">
          {messages.map((msg, index) => (
            <Message
              id={msg.id}
              content={msg.content}
              sender={msg.sender}
              key={index}
              isFirst={index === 0 || messages[index - 1].sender !== msg.sender}
              isLast={
                index === messages.length - 1 ||
                messages[index + 1].sender !== msg.sender
              }
              editable={msg.editable}
              onEdit={(msg, bookingState, state) =>
                submitMessage(msg, bookingState, state)
              }
              nextStatus={msg.editStatus || BookingFlowState.INITIAL}
            />
          ))}
          {renderOptionsDependingOnState()}
          <span id={"end"} ref={div}></span>
        </div>
      </div>
      <div
        className={`chat-orb chat-orb-${!botOpen ? "visible" : "hidden"}`}
        onClick={() => setBotOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          xmlSpace="preserve"
          viewBox="0 0 256 256"
        >
          <g
            style={{
              stroke: "none",
              strokeWidth: 0,
              strokeDasharray: "none",
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 10,
              fill: "none",
              fillRule: "nonzero",
              opacity: 1,
            }}
          >
            <path
              d="M83.755 3.001H6.245A6.245 6.245 0 0 0 0 9.246v50.237a6.245 6.245 0 0 0 6.245 6.245h29.208v21.27l19.095-21.27h29.208a6.245 6.245 0 0 0 6.245-6.245V9.246a6.247 6.247 0 0 0-6.246-6.245zM22.968 40.304a1.396 1.396 0 1 1-2.792 0V30.272a1.396 1.396 0 1 1 2.792 0v10.032zm41.391 4.707a6.688 6.688 0 0 1-6.681 6.681H32.323a6.688 6.688 0 0 1-6.681-6.681V26.777a6.688 6.688 0 0 1 6.681-6.681h11.282v-5.842a1.396 1.396 0 1 1 2.792 0v5.842h11.281a6.688 6.688 0 0 1 6.681 6.681v18.234zm5.465-4.707a1.395 1.395 0 1 1-2.792 0V30.272a1.395 1.395 0 1 1 2.792 0v10.032z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "#fff",
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            />
            <path
              d="M38.885 34.954a1.396 1.396 0 0 1-1.396-1.396v-3.671a1.396 1.396 0 1 1 2.792 0v3.671c0 .771-.625 1.396-1.396 1.396zM51.115 34.954a1.396 1.396 0 0 1-1.396-1.396v-3.671a1.396 1.396 0 1 1 2.792 0v3.671c0 .771-.625 1.396-1.396 1.396zM45 43.985c-2.339 0-4.679-.855-6.954-2.565a1.397 1.397 0 0 1 1.678-2.232c3.6 2.707 6.955 2.706 10.552 0a1.396 1.396 0 0 1 1.678 2.232c-2.275 1.71-4.614 2.565-6.954 2.565z"
              style={{
                stroke: "none",
                strokeWidth: 1,
                strokeDasharray: "none",
                strokeLinecap: "butt",
                strokeLinejoin: "miter",
                strokeMiterlimit: 10,
                fill: "#fff",
                fillRule: "nonzero",
                opacity: 1,
              }}
              transform="matrix(2.81 0 0 2.81 1.407 1.407)"
            />
          </g>
        </svg>
      </div>
    </React.Fragment>
  );
};
