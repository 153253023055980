import "./driver-instructions-input.css";
import { useState } from "react";
import { Button } from "./Button";

export type DriverInstructionsInputType = {
  setDriverInstructions: (instructions: string) => void;
  driverInstructions: string;
};

export const DriverInstructions: React.FC<DriverInstructionsInputType> = ({
  setDriverInstructions: submitDriverInstructions,
  driverInstructions,
}) => {
  const [inputValue, setInputValue] = useState(driverInstructions);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value);
  }

  return (
    <div className="address-input-block">
      <input
        type="text"
        className="address-input"
        value={inputValue}
        onChange={handleChange}
        placeholder="Enter driver instructions..."
      />
      {/*<Button*/}
      {/*  type={"primary"}*/}
      {/*  text={"Confirm"}*/}
      {/*  onClick={() =>*/}
      {/*    (parsedAddress && submitAddress(parsedAddress)) || resetSelection()*/}
      {/*  }*/}
      {/*/>*/}
      <Button
        type="primary"
        text={inputValue !== "" ? "Confirm" : "No special instructions"}
        onClick={() => submitDriverInstructions(inputValue)}
      />
    </div>
  );
};
